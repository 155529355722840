import React, { startTransition, useEffect, useState } from 'react';

// make a new context
const ThemeContext = React.createContext({
  hydrated: false,
});

// create the provider
const ThemeProvider = ({ children }) => {
  const [hydrated, setHydrated] = useState(false);
  useEffect(() => {
      startTransition(() => {
          setHydrated(true)
      })
  }, []);

  return (
    <ThemeContext.Provider value={{
      hydrated: hydrated,
    }}>
      {children}
    </ThemeContext.Provider>
  )
}

export default ThemeContext

export { ThemeProvider };
