/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import { ThemeProvider } from './src/context/theme-context'
import "lazysizes"
import "lazysizes/plugins/blur-up/ls.blur-up"
import "objectFitPolyfill/dist/objectFitPolyfill.basic.min"
import "whatwg-fetch"
import "./src/utils/globals.css"


// Polyfill closest() for IE11
if (!Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var el = this

    do {
      if (Element.prototype.matches.call(el, s)) return el
      el = el.parentElement || el.parentNode
    } while (el !== null && el.nodeType === 1)
    return null
  }
}

export const onRouteUpdate = () => {
  objectFitPolyfill() // eslint-disable-line

  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector
  }
}

export const wrapRootElement = ({ element }) => (
  <ThemeProvider>{element}</ThemeProvider>
)
